// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cena-san-valentino-2020-js": () => import("./../src/pages/cena-san-valentino-2020.js" /* webpackChunkName: "component---src-pages-cena-san-valentino-2020-js" */),
  "component---src-pages-cenone-san-silvestro-js": () => import("./../src/pages/cenone-san-silvestro.js" /* webpackChunkName: "component---src-pages-cenone-san-silvestro-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-veglione-santo-stefano-js": () => import("./../src/pages/veglione-santo-stefano.js" /* webpackChunkName: "component---src-pages-veglione-santo-stefano-js" */)
}

